/* src/components/Gallery.css */
.custom-gallery .image-gallery-slide img {
    border-radius: 16px; /* Adjust the value for desired curve */
    object-fit: cover; /* Ensure the image covers the entire area */
  }
  
  .image-gallery-slide {
    overflow: hidden; /* Hide overflow to maintain rounded corners */
  }
  

  /* src/components/Gallery.css */
.image-gallery .image-gallery-prev,
.image-gallery .image-gallery-next {
  display: none; /* Hide the navigation arrows */
}

/* src/components/Gallery.css */
.image-gallery img {
    pointer-events: none; /* Disable all mouse events */
  }
  